import { PageProps } from 'gatsby';
import { NoteData } from 'packages/innedit';
import React, { FC, useEffect } from 'react';

import Form from '~/containers/Espace/Form';
import note from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceNoteUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { noteId },
  } = props;

  const model = new NoteData({ espaceId: espace.id, params: note });

  useEffect(() => {
    model.incrementView(noteId);
  }, [noteId]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <div className="md:mx-6">
        <Form
          displayDescription
          displayFeatured
          docId={noteId}
          model={model}
          type="update"
        />
      </div>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceNoteUpdate);
